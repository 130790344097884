import { useEffect } from 'react';
import { UAParser } from 'ua-parser-js';

export const userAgentParser = new UAParser(navigator.userAgent);

function checkBrowser(): string | void {
  const os = userAgentParser.getOS().name;

  // Handles mobile ios devices using desktop website
  if ((os === 'iOS' || os === 'Mac OS') && isMobile()) {
    return '';
  }

  if (userAgentParser.getEngine().name !== 'Blink') {
    return 'Chrome is recommended for the best experience.';
  }

  return '';
}

function isMobile(): boolean {
  return (
    userAgentParser.getDevice().type === 'mobile' ||
    userAgentParser.getDevice().type === 'tablet' ||
    navigator.maxTouchPoints > 2
  );
}

function useCheckBrowser(
  onNotRecommendedBrowser: (browserRecommendation: string) => void
) {
  useEffect(() => {
    const browserRecommendation = checkBrowser();
    if (browserRecommendation) onNotRecommendedBrowser(browserRecommendation);
  }, [onNotRecommendedBrowser]);
}

export default useCheckBrowser;
